import React, { useEffect, useState } from 'react'
import { webApiService } from '../../../../services'
import { IAppStore } from '../../../../models/store.model'
import { useSelector } from 'react-redux'
import MUIDataTable from 'mui-datatables'
import MyCustomToolbarSelect from './components/MyCustomToolbarSelect'
import { Button, CircularProgress, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material'
import { UTCDateToCountryDate } from '../../../../utils/countryDate.util'
import { useSnackbar } from 'notistack'
import * as XLSX from 'xlsx'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'

const TableRecibidos = () => {
  const [data, setData] = useState<{ loading: boolean, list: any[], modificados: any[], rowsSelected: any, edit: { loading: boolean, value: string, id: number, active: boolean, indice: number }}>({ loading: false, list: [], modificados: [], rowsSelected: [], edit: { loading: false, value: '', id: 0, active: false, indice: 0 } })
  const userState = useSelector((store: IAppStore) => store.user)
  const { enqueueSnackbar } = useSnackbar()

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'sbu',
      label: 'SBU',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'brand',
      label: 'MARCA',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'model',
      label: 'MODELO',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'serie',
      label: 'IMEI/SERIE',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'status',
      label: 'ESTADO',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'store',
      label: 'TIENDA',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'fecha_entregado',
      label: 'FECHA DE ENTREGA',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue : any) => {
          return UTCDateToCountryDate(value)
        }
      }
    },
    {
      name: 'folio',
      label: 'N° FOLIO',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue : any) => {
          if ([Number(process.env.REACT_APP_PERFIL_EJECUTIVO)].includes(userState.id_role) && Number(process.env.REACT_APP_EJECUTIVO_ENABLE_FOLIO) === 0) {
            return value
          }

          return (data.edit.id !== data.list[tableMeta.rowIndex].id)
            ? (<div>{value} <Button component='label' variant='contained' color='primary' size='small' className='mx-1' onClick={() => { setData({ ...data, edit: { ...data.edit, value, id: data.list[tableMeta.rowIndex].id, active: true, indice: tableMeta.rowIndex } }) }}>editar</Button></div>)
            : (
              <div className='d-flex flex-row'>
                <TextField
                  value={data.edit.value}
                  className='input-stock'
                  type='text'
                  size='small'
                  InputProps={{
                    startAdornment: data.edit.id === data.list[tableMeta.rowIndex].id && data.edit.loading
                      ? (
                        <InputAdornment position='start'>
                          <CircularProgress size={20} />
                        </InputAdornment>
                        )
                      : null
                  }}
                  onChange={event => {
                    // const newList = data.list.map((ti : any, i: number) => ti.id)
                    // setData({ ...data, list: event.target.value })
                    // const newModificados = [...data.modificados]
                    // if (data.list.some(e => e.id === data.list[tableMeta.rowIndex].id)) {
                    //   const indice = newModificados.findIndex(e => e.id === data.list[tableMeta.rowIndex].id)
                    //   newModificados[indice].folio = data.list[tableMeta.rowIndex].folio
                    // } else {
                    //   newModificados.push({ folio: data.list[tableMeta.rowIndex].folio, id: data.list[tableMeta.rowIndex].id, index: tableMeta.rowIndex, equipo: tableMeta.rowData[1] + ' ' + tableMeta.rowData[2] + ' ' + tableMeta.rowData[3] })
                    // }

                    setData({
                      ...data,
                      edit: { ...data.edit, value: event.target.value }
                    })
                  }}
                />
                <Button component='label' variant='contained' color='primary' size='small' className='mx-1' onClick={() => { onEnter(data.list[tableMeta.rowIndex].id) }}>guardar</Button>
              </div>

              )
        }
      }
    }
  ]

  const onEnter = (id: number) => {
    setData({ ...data, edit: { ...data.edit, loading: true } })
    webApiService.updateOnlyFolio({ id, folio: data.edit.value }).then((res) => {
      const newList = data.list.map(item => {
        if (item.id === id) {
          return { ...item, folio: data.edit.value }
        }
        return item
      })

      setData({ ...data, edit: { id: 0, loading: false, value: '', active: false, indice: 0 }, list: [...newList] })
    }).catch((error) => {
      if (error.response !== undefined && error.response.data !== undefined) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' })
      }
      console.log('Error loading data:', error)
      setData({ ...data, edit: { id: 0, loading: false, value: '', active: false, indice: 0 } })
    })
  }

  const loadData = async () => {
    setData({ ...data, loading: true })

    await webApiService.getListTradeIn({ email: userState.email, poolId: String(process.env.REACT_APP_COGNITO_POOL_ID), listStatus: [5], id_role: userState.id_role }).then((res) => {
      console.log('🚀 ~ awaitwebApiService.getListTradeIn ~ res:', res)
      setData({
        ...data,
        list: res.data.map((e: any) => { return { ...e, seleccionado: false } }),
        loading: false,
        modificados: [],
        rowsSelected: []
      })
    })
      .catch((error) => {
        console.log('Error loading data:', error)
        setData({ ...data, loading: false })
      })
  }

  const downloadExcel = () => {
    const obj = data.list.map(ele => ({
      ID: ele.id,
      SBU: ele.sbu,
      MARCA: ele.brand,
      MODELO: ele.model,
      'IMEI/SERIE': ele.serie,
      ESTADO: ele.status,
      TIENDA: ele.store,
      'FECHA DE ENTREGA': UTCDateToCountryDate(ele.fecha_entregado),
      'N° FOLIO': ele.folio
    }))

    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(obj)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Equipos recibidos')
    const fileName = 'Equipos recibidos.xlsx'
    XLSX.writeFile(workbook, fileName)
  }

  useEffect(() => {
    loadData()
    return () => {
      setData({ loading: false, list: [], modificados: [], rowsSelected: [], edit: { id: 0, loading: false, value: '', active: false, indice: 0 } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MUIDataTable
      title=''
      data={data.list}
      columns={columns}
      options={{
        selectableRowsHideCheckboxes: [Number(process.env.REACT_APP_PERFIL_EJECUTIVO)].includes(userState.id_role) && Number(process.env.REACT_APP_EJECUTIVO_ENABLE_FOLIO) === 0,
        print: false,
        download: false,
        viewColumns: false,
        rowsSelected: data.rowsSelected,
        textLabels: {
          selectedRows: { text: 'Filas seleccionadas' }
        },
        onRowSelectionChange: (currentRowsSelected: any[], allRowsSelected: any[], rowsSelected?: any[] | undefined) => {
          console.log('🚀 ~ TableRecibidos ~ rowsSelected:', rowsSelected?.length, data.list.length)

          setData({
            ...data,
            rowsSelected,
            edit: rowsSelected !== undefined && rowsSelected.includes(data.edit.indice) ? { ...data.edit } : { ...data.edit, active: false, id: 0 }
          })
        },
        customToolbarSelect: selectedRows => {
          return selectedRows.data.length
            ? (
              <MyCustomToolbarSelect list={data.list} seleccionados={data.rowsSelected} reloadAction={loadData} visible={data.list.filter((el, i) => data.rowsSelected.includes(i)).every(ele => ele.folio !== null && ele.folio !== '') && !data.edit.active} />
              )
            : null
        },
        customToolbar: () => {
          return (
            <Tooltip title='Download Excel'>
              <IconButton
                sx={{ color: 'default', '&:hover': { color: '#1976D2' } }}
                onClick={downloadExcel}
              >
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
          )
        }
      }}
    />
  )
}

export default TableRecibidos
